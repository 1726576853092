import React, { memo, useState } from 'react'
import './css/sopaletras.css';
import Grid from '@mui/material/Grid';

const BuildSopaLetras = memo(function BuildSopaLetras(props) {
	
    let initialCellBackgrounds = [];
    // Using 1000 as the limit for i we cover allways all cases.
    for(let i = 0 ; i < 1000; i++) {
        initialCellBackgrounds.push({id:i,value:'transparent'});  
    }
    
    const [solutionBoard, setSolutionBoard] = useState('none')
    const [solutionButtonTxt, setSolutionButtonTxt] = useState('Solution')
    const [solutionButtonColor, setSolutionButtonColor] = useState('seagreen')
    const [cellBackgroundColor, setCellBackgroundColor] = useState(initialCellBackgrounds);

    let initialDisplayTranslation = [];
    // Using 30 as the limit for i we cover allways all cases.
    for(let i = 0 ; i < 30; i++) {
        initialDisplayTranslation.push({id:i,value:'none'});  
    }
    const [displayTranslation, setDisplayTranslation] = useState(initialDisplayTranslation);

    
    if(!props.repos || props.repos.length === 0) return <p style={{ color:'#fff', textAlign:'center' }}>API data loading now ...</p>
  
    const sopa_de_letra = props.repos.sopa_de_letra;
    const palabras = props.repos.palabras;
    const side = props.repos.side;
    const translatedPalabras = props.repos.translated_palabras;
    const solutionBoardIndexes = props.repos.solution_board_indexes;
    const cellSize = 30; 
    const boardWith = side * cellSize;


    let sopaLetrasArray = [];
    for(let i = 0; i < sopa_de_letra.length; i++){
        sopaLetrasArray.push(sopa_de_letra[i]);
    }

    let palabrasArray = [];
    let oneWord = '';
    for(let i = 0; i < palabras.length; i++ ){
        oneWord = oneWord.concat(palabras[i]);
        if(palabras[i] === '*'){
            oneWord = oneWord.substring(0,oneWord.length-1);
            palabrasArray.push(oneWord);
            oneWord = '';
        }
    }

    let translatedPalabrasArray = [];
    let oneWordTranslated = '';
    for(let i = 0; i < translatedPalabras.length; i++ ){
        oneWordTranslated = oneWordTranslated.concat(translatedPalabras[i]);
        if(translatedPalabras[i] === '*'){
            oneWordTranslated = oneWordTranslated.substring(0,oneWordTranslated.length-1);
            translatedPalabrasArray.push(oneWordTranslated);
            oneWordTranslated = '';
        }
    }

    let allPalabras = [];
    for(let i = 0; i < translatedPalabrasArray.length; i++ ){
        allPalabras.push([palabrasArray[i],translatedPalabrasArray[i]]);
    }

    let solutionBoardIndexesArray = [];
    let oneIndex = '';
    for(let i = 0; i < solutionBoardIndexes.length; i++ ){
        oneIndex = oneIndex.concat(solutionBoardIndexes[i]);
        if(solutionBoardIndexes[i] === '*'){
            oneIndex = oneIndex.substring(0,oneIndex.length-1);
            solutionBoardIndexesArray.push(parseInt(oneIndex));
            oneIndex = '';
        }

    }
    
    let sopaLetrasSolutionArray = [];
    for(let i = 0; i < sopa_de_letra.length; i++){
        if(solutionBoardIndexesArray.includes(i)){
            sopaLetrasSolutionArray.push([sopa_de_letra[i],'gold']);
        } else {
            sopaLetrasSolutionArray.push([sopa_de_letra[i],'transparent']);
        }
    }

    function handleCrosswordSolutionOnClick(e){
      e.preventDefault();
      if(solutionBoard === 'none'){
        setSolutionBoard('block');  
        setSolutionButtonTxt('Close Solution');
        setSolutionButtonColor('lightgreen');
      } else {
        setSolutionBoard('none');  
        setSolutionButtonTxt('Solution');
        setSolutionButtonColor('seagreen');
      }
        
    }

    return(
        <Grid style={{marginBottom:'3em'}}>
            <div className="sopaLetrasWrapper">
                <div className="sopaLetrasBoard" style={{width:`${boardWith}px`, height:`${boardWith}px`}}>
                    {sopaLetrasArray.map((char, charIndex) => (
                    <div key={charIndex}>
                        <button key={charIndex} onClick={
                            function(e) {
                                e.preventDefault();
                                const newCellBackgrounds = cellBackgroundColor.map((cellData, indexKey) => {
                                    if(indexKey === charIndex){
                                        if(cellData.value === 'transparent'){
                                            cellData.value = 'gold'
                                        } else {
                                            cellData.value = 'transparent'
                                        } 
                                    }
                                    return cellData;
                                });
                                setCellBackgroundColor(newCellBackgrounds);
                            }
                        }
                        
                        style= {{backgroundColor:`${cellBackgroundColor[charIndex].value}`, width:`${cellSize}px`, height:`${cellSize}px`, lineHeight:`${cellSize}px`}} >
                             {char}
                        </button> 
                    </div> 
                    ))}
                </div>
                 
                <div className="copyright"><b>kobikibu©</b></div>
                <div className="wordsList">
                    {allPalabras.map((word, wordIndex) => (
                        <button key={wordIndex} 
                        onClick={
                            function(e) {
                                e.preventDefault();
                                const newDisplayTranslation = displayTranslation.map((item, indexItem) => {
                                    if(indexItem === wordIndex){
                                        if(item.value === 'none'){
                                            item.value = 'block'
                                        } else {
                                            item.value = 'none'
                                        } 
                                    }
                                    return item;
                                });
                                setDisplayTranslation(newDisplayTranslation);
                            }
                        }>{word[0]}
                            <span style={{ display:`${displayTranslation[wordIndex].value}`}}>=<br></br>{word[1]}</span>
                        </button>
                    ))}
                </div>
                <div className="solutionButton">
                    <button onClick={handleCrosswordSolutionOnClick} style={{ backgroundColor:`${solutionButtonColor}`}}><b>{solutionButtonTxt}</b></button>
                </div>
                    <div className="sopaLetrasBoardSolution" style={{display:`${solutionBoard}`}}>

                        <div className="sopaLetrasBoard" style={{marginTop:'1em',width:`${boardWith}px`, height:`${boardWith}px`}}>
                            {sopaLetrasSolutionArray.map((char, charIndex) => (
                            <div key={charIndex}>
                                <button key={charIndex} style= {{backgroundColor:`${char[1]}`, width:`${cellSize}px`, height:`${cellSize}px`, lineHeight:`${cellSize}px`}} >
                                    {char[0]}
                                </button> 
                            </div> 
                            ))}
                    </div>
                    <div className="copyright"><b>kobikibu©</b></div>
                </div>
            </div> 
        </Grid>
    );
});

export default BuildSopaLetras