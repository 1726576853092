import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import WithDataLoading from './api/WithDataLoading';
import RandomNumberInRange from './api/RandomNumberInRange';
import BuildSopaLetras from './BuildSopaLetras';

const SopaLetras = () => {

    const DataLoading = WithDataLoading(BuildSopaLetras);

    // Fetch data from API using the Axios library
    // Remember Axios needs to be installed : npm install axios
    const[sopaLetrasData, setSopaLetrasData] = useState({
        loading: false,
        repos : null,
    });
   
    useEffect(() => {
        setSopaLetrasData({loading:true});
        //Axios.get('http://localhost:3001/getSopaLetras')
        Axios.get('https://gurutzegramak.herokuapp.com/getSopaLetras')
        .then(repos => {
            const size = repos.data.length;
            const chosenItemNum = RandomNumberInRange(0,size-1);
            const chosenSopaLetrasData = repos.data[chosenItemNum];
            setSopaLetrasData({loading:false , repos:chosenSopaLetrasData});
        })
        .catch(error => {
            console.log(error);
        });
    }, [setSopaLetrasData]);

    return (
        <React.Fragment>
            <BuildSopaLetras isLoading={sopaLetrasData.loading} repos={sopaLetrasData.repos} />
        </React.Fragment>
    );
}

export default SopaLetras;